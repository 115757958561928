import { AppConst } from "@app/const.generated";
import { WorkQueue_ManualTaskType, WorkQueue_TrackTraceTaskType, WorkTaskStatus} from "@wearewarp/types";
import { WorkTaskPriorityMask } from "@wearewarp/types/data-model/types/WorkTask";

export class Const {

  // Route
  public static readonly routeLogin = `/login`;
  public static readonly routeVerifyLogin = `/verify`;
  public static readonly routeDashboard = '/dashboard';
  public static readonly routeAdminOrderList = `${this.routeDashboard}/orders`;
  public static readonly routeAdminClientList = `${this.routeDashboard}/customers`;
  public static readonly routeAdminCarrierList = `${this.routeDashboard}/carriers`;
  public static readonly routeAdminDriverList = `${this.routeDashboard}/drivers`;
  public static readonly routeAdminUserList = `${this.routeDashboard}/users`;
  public static readonly routeAdminDispatchList = `${this.routeDashboard}/dispatch`;

  // API URI backend origin
  public static readonly APIURI_TIMEOUT_TEST = 'http://google.com:81';
  public static readonly API_SEARCH_US_CITIES = 'https://gw.wearewarp.com/us-cities/v1/search/city';
  public static readonly APIURI_MY_PROFILE = Const.API_ME('my_profile');
  public static readonly APIURI_TASKS = 'tasks';
  public static readonly APIURI_JOBS = 'jobs';
  public static readonly APIURI_JOBS_V2 = 'jobs-v2'
  public static readonly APIURI_USERS = 'users';
  public static readonly APIURI_ORDERS = 'orders';
  public static readonly APIURI_CONVERSATIONS = 'v2/conversations';
  public static readonly APIURI_JOB_NOTIFICATIONS = 'job_notifications';
  public static readonly APIURI_DRIVERS = 'drivers';
  public static readonly APIURI_CARRIERS = 'carriers';
  public static readonly APIURI_DOWNLOAD = 'dl';
  public static readonly APIURI_WORK_QUEUE = Const.APIV2('work_queue');
  
  // API backend work queue
  public static readonly API_CREATE_TASK = 'v1/w/d/tasks';        // method POST
  public static readonly API_FETCH_TASK = 'v1/w/d/tasks/get-queue-task';
  public static API_COMPLETE_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/complete-task` };
  public static API_UPDATE_TASK_ANSWER(taskId: string): string { return `v1/w/d/tasks/${taskId}/update-answer` };
  public static API_GET_SPECIFIC_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}` };
  public static API_PAUSE_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/pause-task` };
  public static API_UPDATE_TASK_PRIORITY(taskId: string): string { return `v1/w/d/tasks/${taskId}/update-priority` };
  public static API_UPDATE_JOB_TT_TASKS_PRIORITY(jobId: string): string { return `v1/w/d/tasks/job/${jobId}/update-priority-tracktrace-tasks` };
  public static API_TRACKTRACE_GET_TASK_FOCUS_ON_ROUTE(jobId: string): string { return `v1/w/d/track-trace/task-focus-on-route/${jobId}` };

  public static API_GET_LOG_HISTORY(taskId: string): string { return `v1/w/d/tasks/${taskId}/get-histories`};         // not used
  public static API_UPDATE_LOG_HISTORY(taskId: string): string { return `v1/w/d/tasks/${taskId}/update-history` };    // not used
  /**
   * @deprecated
   * will remove soon
   */
  public static API_COMPLETE_SPECIFIC_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/complete-specific-task` };

  public static API_GET_CHANNELS_FOR_TRACKTRACE_TASK(jobId: string): string { return `v1/channels_notification/jobs/${jobId}/list_channels_for_track_trace_task` };
  public static API_NOTIFY_SLACK(): string { return `v1/channels_notification/notify_slack` };
  public static readonly APIURI_LIST_OPERATORS = `v1/users/list-operators`;
  public static readonly APIURI_TRACK_TRACE_STATISTIC = `v1/w/d/track-trace/statistics`;

  public static APIV2(path: string): string { return `v2/${path}` };
  public static API_SESSION(): string { return 'ss?e=1' };
  public static API_ME(action: string = null): string { return action ? `me/${action}` : 'me' }
  public static API_PUBLIC(action: string): string { return `p/${action}` };

  public static MAPBOX_STYLE(name: string): string {
    return `mapbox://styles/mapbox/${name}`;
  }

  public static readonly DATETIME_FORMAT_FROM_DB = 'YYYY-MM-DD HH:mm:ss';
  public static readonly FORMAT_GUI_DATETIME_SHORT = 'M/D/YY, h:mm a';
  public static readonly FORMAT_GUI_DATE = 'MMM D, YYYY';
  public static readonly FORMAT_GUI_DATETIME = 'MMM D, YYYY, h:mm a';
  public static readonly FORMAT_GUI_DATETIME_V3 = 'MM/DD/YYYY, h:mm A';
  public static readonly MOBILE_WIDTH = 568;

  public static readonly ENCRYPT_KEY = 'y1pSrKRLLkCQGbt0rqA5DJh3XnjDG3j9';
  public static readonly ENCRYPT_IV = 'U0mkzdFK7naqEn60';

  public static readonly LANG_EN = 'en';
  public static readonly LANG_VI = 'vi';
  public static readonly WORK_DISPATCHER_APP_ID: number = 13;
  public static readonly APP_HTTP_HEADER: string = `${Const.WORK_DISPATCHER_APP_ID};${AppConst.Version};${AppConst.BuildWhen.replace(/[\/\s:]/g, '')}`;

  public static readonly WorkTaskObjectEntity = {
    job: 'job'
  }

  static get WorkRouteTask(): { [type in WorkQueue_TrackTraceTaskType]: WorkQueue_TrackTraceTaskType } {
    return {
      routeConfirmation: 'routeConfirmation',
      otpConfirmation: 'otpConfirmation',
      etaConfirmation: 'etaConfirmation',
      arrivalConfirmation: 'arrivalConfirmation',
      departureConfirmation: 'departureConfirmation',
      enrouteTracking: 'enrouteTracking',
    }
  }

  public static get WorkRouteTaskLabel(): { [type in WorkQueue_TrackTraceTaskType]: string} {
    return {
      routeConfirmation: 'Confirm Booking',
      otpConfirmation: '24 hour check',
      etaConfirmation: 'Confirm ETA',
      arrivalConfirmation: 'Confirm Arrival',
      departureConfirmation: 'Confirm Departure',
      enrouteTracking: 'En-route Tracking',
    }
  }
  
  public static get WorkManualTask(): { [type in WorkQueue_ManualTaskType]: WorkQueue_ManualTaskType } {
    return {
      manual: 'manual'
    }
  }

  public static get WorkTaskStatus(): {[key in WorkTaskStatus]: WorkTaskStatus} {
    return {
      onHold: 'onHold',
      pending: 'pending',
      inProcess: 'inProcess',
      paused: 'paused',
      completed: 'completed',
      escalated: 'escalated'
    }
  }

  public static readonly JobStatus = {
    created: "created",
    inProgress: "inProgress",
    canceled: "canceled",
    completed: "completed",
  }

  public static readonly JobType = {
    normal: "normal",
    ghost: "ghost",
    external: "external",
    crossdock: "crossdock",
    linehaul: "linehaul",
  }

  public static getJobStatusText(status) {
    if (!status) {
      return 'Created';
    }
    switch (status) {
      case this.JobStatus.created: return  'Created';
      case this.JobStatus.inProgress: return 'In Progress';
      case this.JobStatus.completed: return 'Completed';
      case this.JobStatus.canceled: return 'Canceled';
      default: return status;
    }
  }

  public static readonly StopStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static readonly RouteTaskStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static getStopStatusText(status) {
    switch (status) {
      case this.StopStatus.created: return  'Created';
      case this.StopStatus.enroute: return 'En-route';
      case this.StopStatus.arrived: return 'Arrived';
      case this.StopStatus.succeeded: return 'Succeeded';
      case this.StopStatus.failed: return 'Failed';
      case this.StopStatus.canceled: return 'Canceled';
      case this.StopStatus.pickupFailed: return 'Pickup Failed';
      default: return status;
    }
  }

  public static getTaskTypeText(status) {
    switch(status) {
      case "PICKUP": return "Pickup";
      case "DROPOFF": return "Dropoff";
      case "TRANSIT": return "Transit";
      case "RETURN": return "Return";
      case "RETURN_DEPOT": return "Return Depot";
      default: return status;
    }
  }

  public static readonly ShipmentType = {
    FTL: "FTL",
    LTL: "LTL",
    PARCEL: "PARCEL"
  }

  public static readonly RouteTaskType = {
    PICKUP: 'PICKUP',
    DROPOFF: 'DROPOFF'
  }

  public static readonly TaskType = {
    PICKUP: "PICKUP",
    DROPOFF: "DROPOFF",
    TRANSIT: "TRANSIT",
    RETURN: "RETURN",
    RETURN_DEPOT: "RETURN_DEPOT"
  }

  public static readonly UpdateByCollection = {
    users: 'users',
  }

  public static readonly DriverStatus = {
    active: 1
  }

  public static readonly LogAction = {
    updateStopStatus: 'update_stop_status',
    sendConfirmBookingEmail: 'sendConfirmBookingEmail'
  }

  public static readonly CarrierId = {
    exfreight: '01H22NK3ZVSGS061RVSPXTS56T'
  } 

  public static readonly CountryId_USA = '61e46975b8ead47e3020fbac';
  
  public static get JobNotificationType() {
    return {
      needCarrier: "needCarrier",
      assignPIC: "assignPIC",
      needPOD: "needPOD",
      podAvailableReview: "podAvailableReview",
      delayed: "delayed",
    } 
  }

  public static readonly listWarpSystemIds = [
    '01H22NNN7XWAKXJ6SSB10X7MK8'  // cronjob
  ]

  public static get PriorityMask(): {[type in WorkTaskPriorityMask]: WorkTaskPriorityMask} {
    return {
      urgent: 'urgent',
      high: 'high',
      medium: 'medium',
      low: 'low',
    }
  }
}
