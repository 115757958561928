export enum ApiMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete'
}

export enum SocketEvent {
  welcome = 'welcome',
  joinRoom = 'joinRoom',
  leaveRoom = 'leaveRoom',
  jobFollow = 'jobFollow',
  jobRequestLiveStatus = 'jobRequestLiveStatus',
  jobStatusChange = 'jobStatusChange',
  taskStatusChange = 'taskStatusChange',
  driverAppCommand = 'driverAppCommand',
  driverFollow = 'driverFollow',
  driverRequestOnlineStatus = 'driverRequestOnlineStatus',
  driverOnlineStatus = 'driverOnlineStatus',
  driverRequestMobileAppStatus = 'driverRequestMobileAppStatus',
  driverMobileAppStatus = 'driverMobileAppStatus',
  driverRequestStartBackgroundLocation = 'driverRequestStartBackgroundLocation',
  driverRequestLastKnownLocation = 'driverRequestLastKnownLocation',
  driverLocation = 'driverLocation',
  driverTrackLocation = 'driverTrackLocation',
  driverUnTrackLocation = 'driverUnTrackLocation',
  /**
   * @deprecated use driverLocation instead
   */
  updateDriverLocation = 'updateDriverLocation',
}