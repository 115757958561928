import { map, of } from "rxjs";
import { ApiService } from "./api.service";
import { Const } from "@const/Const";
import { Log } from "./log";
import { DateUtil } from "./date-util";
import { DelayCode } from "@wearewarp/types/data-model";
import { TaskType } from "@wearewarp/types";

export interface MasterWorkQueue {
  timezones: any[];
  mapBoxAccessToken?: string,
  countries: any[],
  countries_states: any,
  delay_codes: DelayCode[]
}

export class MasterData {
  private static data: MasterWorkQueue = <any>{};
  private static mapCountries = {};  // map by _id
  private static countryUS: any = {};
  private static canadaProvinces: any = {};

  public static fetch(api: ApiService) {
    if (Object.keys(this.data).length > 0) {
      return of(this.data);   // already had data
    }
    let url = Const.API_PUBLIC('masters');
    return api.GET(url).pipe(
      map(resp => {
        Log.d('master data: ', resp);
        this.set(resp.data);
        return resp.data;
      }) 
    )
  }

  public static get mapboxToken() {
    return this.data.mapBoxAccessToken;
  }

  public static set(data: MasterWorkQueue) {
    this.data = Object.assign(this.data || {}, data);
    if (data?.timezones) {
      DateUtil.listTimezones = data.timezones;
    }
    if (data?.countries) {
      for (let i = 0; i < data.countries.length; i++) {
        this.mapCountries[data.countries[i]._id] = data.countries[i];
        if (data.countries[i].alpha2Code == 'US') {
          this.countryUS = data.countries[i];
        }
      }
    }
    if (data?.countries_states?.canada_provinces){
      this.canadaProvinces = data?.countries_states?.canada_provinces
    }
  }

  public static getStatesUS(): Array<any> {
    return this.countryUS.states || [];
  }

  public static getCanadaProvinces(): Array<any> {
    return this.canadaProvinces;
  }

  public static getStateUSByCode(code: string) {
    for (let item of this.countryUS.states) {
      if (item.code == code) {
        return item;
      }
    }
    return null;
  }

  public static getDelayCodes(): Array<DelayCode> {
    return this.data.delay_codes;
  }
  public static getDelayCodesByType(type: TaskType): Array<DelayCode> {
    const delayCodeType = {
      PICKUP: 'PICKUP',
      DROPOFF: 'DELIVERY'
    }[type];
    const list = this.data.delay_codes?.filter(it => it.type == delayCodeType);
    return list?.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)) || [];
  }
  
  public static getCountryNameById(id) {
    if (!this.mapCountries[id]) return id;
    return this.mapCountries[id].name;
  }
}