import { Routes } from '@angular/router';
import { PublicComponent } from './public';
import { Login } from './auth/login';
import { VerifyLogin } from './auth/verify-login';

export const APP_ROUTES: Routes = [
  { path: '', component: PublicComponent,
    children: [
      { path: 'login', component: Login, data: { title: 'Login - WARP Work'} },
      { path: 'verify', component: VerifyLogin, data: { title: 'Verify login - WARP Work'} }    
    ]
  },
  // { path: 'dashboard', loadChildren: () => import('./work-dispatcher/dashboard.routing').then(m => m.DASHBOARD_ROUTES) },
  // { path: 'track-trace', loadChildren: () => import('./track-trace/track-trace.routing').then(m => m.TRACK_TRACE_ROUTES) },
  { path: 'dashboard', loadChildren: () => import('./work-dispatcher-v2/dashboard.routing').then(m => m.DASHBOARD_ROUTES_V2) },
  { path: '**', redirectTo: ''},
];
