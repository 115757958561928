/* 
Generated when 2025-03-11 18:27:05
EN: This file is generated automatically, don't edit manually. Please modify lang_frontend.json if necessary and then this file will be updated automatically.
VI: File này được sinh tự động, đừng sửa trực tiếp vào đây, nếu cần thì hãy sửa file lang_frontend.json sau đó file này sẽ được tự động update theo.
*/

export class KeyLang {
  public static readonly Lbl_Sort = "Lbl_Sort";
  public static readonly Txt_FilterNone = "Txt_FilterNone";
  public static readonly Txt_Keyword = "Txt_Keyword";
  public static readonly Txt_page = "Txt_page";
  public static readonly Txt_STT = "Txt_STT";
  public static readonly Txt_NoData = "Txt_NoData";
  public static readonly Txt_NotAllowLoginAdmin = "Txt_NotAllowLoginAdmin";
  public static readonly Txt_ConfirmLogout = "Txt_ConfirmLogout";
  public static readonly Txt_CannotLoginByFacebook = "Txt_CannotLoginByFacebook";
  public static readonly Txt_LoginNotice1 = "Txt_LoginNotice1";
  public static readonly Txt_LoginNotice2 = "Txt_LoginNotice2";
  public static readonly Txt_FunctionRequireLogin = "Txt_FunctionRequireLogin";
  public static readonly Txt_RequiredField = "Txt_RequiredField";
  public static readonly Txt_Email = "Txt_Email";
  public static readonly Txt_Password = "Txt_Password";
  public static readonly Txt_Login = "Txt_Login";
  public static readonly Txt_LastLogin = "Txt_LastLogin";
  public static readonly Txt_LoginRegister = "Txt_LoginRegister";
  public static readonly Txt_LoginBy = "Txt_LoginBy";
  public static readonly Txt_Logout = "Txt_Logout";
  public static readonly Txt_Error = "Txt_Error";
  public static readonly Txt_None = "Txt_None";
  public static readonly Txt_Success = "Txt_Success";
  public static readonly Txt_Notification = "Txt_Notification";
  public static readonly Txt_Save = "Txt_Save";
  public static readonly Txt_OK = "Txt_OK";
  public static readonly Txt_Cancel = "Txt_Cancel";
  public static readonly Txt_No = "Txt_No";
  public static readonly Txt_NotDelete = "Txt_NotDelete";
  public static readonly Txt_NotRemove = "Txt_NotRemove";
  public static readonly Txt_ListAccount = "Txt_ListAccount";
  public static readonly Txt_Approve = "Txt_Approve";
  public static readonly Txt_Reject = "Txt_Reject";
  public static readonly Txt_Role = "Txt_Role";
  public static readonly Txt_Image = "Txt_Image";
  public static readonly Txt_Vietnamese = "Txt_Vietnamese";
  public static readonly Txt_English = "Txt_English";
  public static readonly Txt_Language = "Txt_Language";
  public static readonly Txt_Profile = "Txt_Profile";
  public static readonly Txt_Name = "Txt_Name";
  public static readonly Txt_Code = "Txt_Code";
  public static readonly Txt_FullName = "Txt_FullName";
  public static readonly Txt_ChangePw = "Txt_ChangePw";
  public static readonly Txt_TwoFa = "Txt_TwoFa";
  public static readonly Txt_OldPw = "Txt_OldPw";
  public static readonly Txt_NewPw = "Txt_NewPw";
  public static readonly Txt_ConfirmPw = "Txt_ConfirmPw";
  public static readonly Txt_Filter = "Txt_Filter";
  public static readonly Txt_Edit = "Txt_Edit";
  public static readonly Txt_PlaceholderSearchUsers = "Txt_PlaceholderSearchUsers";
  public static readonly Txt_AddUser = "Txt_AddUser";
  public static readonly Txt_ResetPwUser = "Txt_ResetPwUser";
  public static readonly Txt_CreateNew = "Txt_CreateNew";
  public static readonly Txt_CreateAt = "Txt_CreateAt";
  public static readonly Txt_UpdateAt = "Txt_UpdateAt";
  public static readonly Txt_Delete = "Txt_Delete";
  public static readonly Txt_Remove = "Txt_Remove";
  public static readonly Txt_UndoDelete = "Txt_UndoDelete";
  public static readonly Txt_Version = "Txt_Version";
  public static readonly Txt_CreatedBy = "Txt_CreatedBy";
  public static readonly Txt_Refresh = "Txt_Refresh";
  public static readonly Txt_OldPwHint = "Txt_OldPwHint";
  public static readonly Txt_NewPwHint = "Txt_NewPwHint";
  public static readonly Txt_PwHint = "Txt_PwHint";
  public static readonly Txt_ConfirmPwHint = "Txt_ConfirmPwHint";
  public static readonly Txt_ResetNewPw = "Txt_ResetNewPw";
  public static readonly Txt_ListSum = "Txt_ListSum";
  public static readonly Txt_Total = "Txt_Total";
  public static readonly Txt_ChangePwSuccess = "Txt_ChangePwSuccess";
  public static readonly Txt_ResetPwUserSuccess = "Txt_ResetPwUserSuccess";
  public static readonly Txt_ChangePwFailed = "Txt_ChangePwFailed";
  public static readonly Txt_TimeoutError = "Txt_TimeoutError";
  public static readonly Txt_ConnectionError = "Txt_ConnectionError";
  public static readonly Txt_HintEnterEmail = "Txt_HintEnterEmail";
  public static readonly Txt_HintSearch = "Txt_HintSearch";
  public static readonly Txt_HintEnterFullName = "Txt_HintEnterFullName";
  public static readonly Txt_ErrorRetryLater = "Txt_ErrorRetryLater";
  public static readonly Txt_NewAccount = "Txt_NewAccount";
  public static readonly Txt_CreateNewAccount = "Txt_CreateNewAccount";
  public static readonly Txt_CreateAccountGuide = "Txt_CreateAccountGuide";
  public static readonly Txt_RegistrationCode = "Txt_RegistrationCode";
  public static readonly Txt_ForgotPassword = "Txt_ForgotPassword";
  public static readonly Txt_CannotLogin = "Txt_CannotLogin";
  public static readonly Txt_BackToLogin = "Txt_BackToLogin";
  public static readonly Txt_SendResetPwCode = "Txt_SendResetPwCode";
  public static readonly Txt_ResetPwCode = "Txt_ResetPwCode";
  public static readonly Txt_EnterOtp = "Txt_EnterOtp";
  public static readonly Txt_ForgotPwdGuide = "Txt_ForgotPwdGuide";
  public static readonly Txt_RequireNumberCharacters = "Txt_RequireNumberCharacters";
  public static readonly Txt_NoResult = "Txt_NoResult";
  public static readonly Txt_ConfirmSubmitEdit = "Txt_ConfirmSubmitEdit";
  public static readonly Txt_ConfirmApprove = "Txt_ConfirmApprove";
  public static readonly Txt_ConfirmReject = "Txt_ConfirmReject";
  public static readonly Txt_PlsEnterReason = "Txt_PlsEnterReason";
  public static readonly Txt_InformSuccess = "Txt_InformSuccess";
  public static readonly Txt_SaveBeforeRoute = "Txt_SaveBeforeRoute";
  public static readonly Txt_ConfirmCancel = "Txt_ConfirmCancel";
  public static readonly Txt_ConfirmCancelYes = "Txt_ConfirmCancelYes";
  public static readonly Txt_ConfirmCancelNo = "Txt_ConfirmCancelNo";
  public static readonly Txt_ConfirmDelete = "Txt_ConfirmDelete";
  public static readonly Txt_Profile_lastActivities = "Txt_Profile_lastActivities";
  public static readonly Txt_SttNew = "Txt_SttNew";
  public static readonly Txt_SttDone = "Txt_SttDone";
  public static readonly Txt_ItemDeleted = "Txt_ItemDeleted";
  public static readonly Txt_Yesterday = "Txt_Yesterday";
  public static readonly Txt_AppInitError = "Txt_AppInitError";
  public static readonly Txt_CarrierManagement = "Txt_CarrierManagement";
  public static readonly Txt_ShipperManagement = "Txt_ShipperManagement";
  public static readonly Txt_OrderManagement = "Txt_OrderManagement";
  public static readonly Txt_Dispatch = "Txt_Dispatch";
  public static readonly Txt_NewVehicle = "Txt_NewVehicle";
  public static readonly Txt_NewCarrier = "Txt_NewCarrier";
  public static readonly Txt_CarrierPricing = "Txt_CarrierPricing";
}
