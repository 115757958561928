import { Utils } from './utils';

export class RoleManager {
  public static readonly root = 'root';
  public static readonly systemAdmin = 'systemAdmin';
  public static readonly admin = 'admin-legacy';
  public static readonly newAdmin = 'admin';
  public static readonly dispatcher = 'dispatcher';
  public static readonly adminReadOnly = 'adminReadOnly';
  public static readonly client = 'client';
  public static readonly carrier = 'carrier';
  public static readonly driver = 'driver';
  public static readonly sale = 'sale';
  public static readonly accounting = 'accounting';
  public static readonly accountingReadOnly = 'accountingReadOnly';
  public static readonly userManagement = 'userManagement'
  public static readonly carrierManager = 'carrierManager'
  /**
   * @deprecated use warehouseOperator instead
   */
  public static readonly crossdockOperator = 'crossdockOperator';
  public static readonly warehouseOperator = 'crossdockOperator';
  public static readonly warehouseAdmin = 'warehouseAdmin';
  public static readonly warehouseInternal = 'warehouseInternal';
  public static readonly warehouseExternal = 'warehouseExternal';
  public static readonly customerReadOnly = 'customerReadOnly';
  
  public static readonly rootId = '5d6a1b55c6b3d2176cd64aa5';
  public static readonly systemAdminId = '5e6efd8751827d2235885db6';
  public static readonly systemSettingId = '6229370ee7f9389ef8be6554';
  public static readonly adminId = '5e34f3be58ff8e274b8efbe4';
  public static readonly newAdminId = '65f26d7f25717bf0f00e1f91';
  public static readonly dispatcherId = '65f26dc225717bf0f00e1f92';
  public static readonly adminReadOnlyId = '62748843ba69f5077594c722';
  public static readonly clientId = '619b8c25124190c2d87836fb';
  public static readonly carrierId = '619b8c2e124190c2d87836ff';
  public static readonly driverId = '622020e3bf841c84ea6fc037';
  public static readonly saleId = '6226a05be7f9389ef8bdf4ea';
  public static readonly accountingId = '627ad28f8b5f152f33eeb88a';
  /**
   * @deprecated use warehouseOperatorId instead
   */
  public static readonly crossdockOperatorId = '527ad28f8b5f152f33eeb88b';
  public static readonly warehouseOperatorId = '527ad28f8b5f152f33eeb88b';
  public static readonly warehouseAdminId = '649acf1144dbe313a2d381cd';
  public static readonly warehouseInternalId = '649acf1144dbe313a2d38189';
  public static readonly warehouseExternalId = '649acf1144dbe313a2d38279';
  public static readonly userManagementId = '65d42a3c6ecac719de0764c3';
  public static readonly carrierManagerId = '662756cbdf71f903b146133f';
  public static readonly customerReadOnlyId = '66d98ef8392cf9a4bcbff744';

  public static readonly rolesForAccessUsers = [RoleManager.admin, RoleManager.adminReadOnly, RoleManager.systemAdmin, RoleManager.root, RoleManager.userManagement, RoleManager.carrierManager]

  // Có quyền xem và sửa user's account
  public static canAccessUsers(authUser) {
    return RoleManager.userHasOneOfRoles(authUser, RoleManager.rolesForAccessUsers);
  }

  public static userHasNoRole(user): boolean {
    return !user || !Utils.isArrayNotEmpty(user.roles);
  }

  public static userHasRole(user, roleCode: string): boolean {
    if (RoleManager.userHasNoRole(user)) {
      return false;
    }
    for (let role of user.roles) {
      if (role.code === roleCode) {
        return true;
      }
    }
    return false;
  }

  public static userHasOneOfRoles(user, roleCodes: Array<string>): boolean {
    if (RoleManager.userHasNoRole(user)) {
      return false;
    }
    for (let role of user.roles) {
      if (roleCodes.includes(role.code)) {
        return true;
      }
    }
    return false;
  }

  public static isAdminReadOnlyRole(user) {
    return !RoleManager.userHasRole(user, RoleManager.admin) &&
            RoleManager.userHasRole(user, RoleManager.adminReadOnly)
  }

  // Chỉ có quyền admin mới đc login vào sử dụng web
  // Sau có cho thêm quyền gì nữa thì add vào đây
  public static allowLogin(user): boolean {
    if (RoleManager.userHasNoRole(user)) {
      return false;
    }
    for (let role of user.roles) {
      switch (role.code) {
        case RoleManager.admin:
        case RoleManager.adminReadOnly:
          return true;
        default:
          break;
      }
    }
    return false;
  }

}
