import { Injectable, EventEmitter } from '@angular/core';
import { Const } from '@const/Const';
import * as json from './lang_frontend.json';
import { LocalStorageKey } from '@const/LocalStorageKey';

const langJson = json['default'];
const LANG_DEFAULT = Const.LANG_EN;

@Injectable()
export class TranslateService {
  public static readonly PLACEHOLDER = '%';
  private _currentLang: string;
  public onLangChanged: EventEmitter<string> = new EventEmitter<string>();

  public get currentLang() {
    if (!this._currentLang) {
      this._currentLang = TranslateService.getCurrentLanguage();
    }
    return this._currentLang;
  }

  public static getCurrentLanguage() {
    let lang = localStorage.getItem(LocalStorageKey.CURRENT_LANG);
    if (!lang) {
      lang = LANG_DEFAULT;
    }
    return lang;
  }

  constructor() {
  }

  public use(lang: string): boolean {
    return this.setLanguage(lang);
  }

  public instant(key: string, words?: string | string[] | number | number[]) {
    const txt = this.translate(key);
    if (words === undefined || words === null) {
      return txt;
    }
    return TranslateService.replace(txt, words);
  }

  public static replace(word: string = '', words: string | string[] | number | number[] = '') {
    let translation: string = word;
    const values: string[] = [].concat(words);
    values.forEach((e, i) => {
      translation = translation.replace(TranslateService.PLACEHOLDER.concat(<any>i), e);
    });
    return translation;
  }

  private translate(key: string): string {
    if (langJson[key] && langJson[key][this.currentLang]) {
      return langJson[key][this.currentLang];
    }
    if (LANG_DEFAULT != this.currentLang && langJson[key] && langJson[key][LANG_DEFAULT]) {
      return langJson[key][LANG_DEFAULT];
    }
    return key;
  }

  private setLanguage(lang: string): boolean {
    switch (lang) {
      case Const.LANG_VI:
      case Const.LANG_EN:
        break;
      default:
        return false;
    }
    if (this._currentLang && this._currentLang === lang) {
      return false;
    }
    this._currentLang = lang;
    localStorage.setItem(LocalStorageKey.CURRENT_LANG, lang);
    this.onLangChanged.emit(lang); // publish changes
    return true;
  }

}
