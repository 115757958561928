export const PermissionCode = {
  login: {
    work: `login:work`
  },
  work_task: {
    get_specific_task: 'work_task:get_specific_task',
    complete_specific_task: 'work_task:complete_specific_task'
  },
  payment: {
    send_issue: `payment:send_issue`,
    view_issue: `payment:view_issue`,
  }
}