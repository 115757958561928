
import CryptoJSCore from 'crypto-js/core'
import Hex from 'crypto-js/enc-hex';
import Utf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64';
import { Const } from "@const/Const";

export class Crypto {

  public static encryptText(input: string, options: {key?: string, iv?: string, enc?: 'base64'|'hex'} = {}) {
    let key: string = options.key || Const.ENCRYPT_KEY;
    let iv: string = options.iv || Const.ENCRYPT_IV;
    let encodingMode = (options.enc || 'base64') == 'hex' ? Hex : Base64;
    let ops = {
      mode: CryptoJSCore.mode.CBC,
      iv: Utf8.parse(iv)
    };
    let enc = AES.encrypt(input, Utf8.parse(key), ops);
    return enc.ciphertext.toString(encodingMode);
  }

  public static decryptText(input: string, options: {key?: string, iv?: string, enc?: 'base64'|'hex'} = {}) {
    let key: string = options.key || Const.ENCRYPT_KEY;
    let iv: string = options.iv || Const.ENCRYPT_IV;
    let encodingMode = (options.enc || 'base64') == 'hex' ? Hex : Base64;
    let ops = {
      mode: CryptoJSCore.mode.CBC,
      iv: Utf8.parse(iv)
    };
    let dec = AES.decrypt(encodingMode.parse(input).toString(), Utf8.parse(key), ops);
    return dec.toString(Utf8);
  }
}