import { AWSUtil } from "./aws-util";
import { ApiService } from "./api.service";
import { UploadFileData } from "@wearewarp/types/rest-api";
import { Const } from '@const/Const';
import { HttpResponse } from '@angular/common/http';
import { Utils } from './utils';
import { filesize } from 'filesize';
import { AttachedFile } from '@wearewarp/types/data-model';
import { UIHelper } from './UIHelper';

export class AttachedFileUtil {
  public static isAttachedFile(item): boolean {
    return item && item.s3Key;
  }

  public static isPdf(item: {type?: string, name?: string}): boolean {
    if (!item) return false;
    if (typeof item == 'string') return (<string>item).split('?')[0].toLowerCase().endsWith('.pdf');
    if (item.type && item.type.toLowerCase() == 'application/pdf') return true;
    if (item.name && item.name.toLowerCase().endsWith('.pdf')) return true;
    return false;
  }

  public static attachedFileUrl(model: UploadFileData, usePresigned = false): string {
    if (!model) {
      return '';
    }
    if (model.s3Key) {
      if(usePresigned){
        return AWSUtil.getSignedUrlS3Object(model.s3Key, model.s3Bucket);
      }
      return AWSUtil.getUrlS3Object(model.s3Key, model.s3Bucket);
    }
    return `${ApiService.getImageUrl(model.url)}?name=${encodeURIComponent(model.name)}`;
  }

  public static downloadAttachedFile(attachedFile: AttachedFile) {
    let id = attachedFile.id || attachedFile._id;
    let url = `${Const.APIURI_DOWNLOAD}/${id}`;
    attachedFile.isDownloading = true;
    let fileName = attachedFile.name;
    let fileType = attachedFile.type;
    ApiService.instance.GET(url, {observe: 'response', responseType: 'arraybuffer'}).subscribe(
      (resp: HttpResponse<any>) => {
        let body = resp.body;
        if (!fileName) {
          let contentDisposition = resp.headers.get('Content-Disposition');
          fileName = Utils.getFileNameFromContentDisposition(contentDisposition);
        }
        if (!fileType) {
          fileType = resp.headers.get('Content-Type');
        }
        let blob = new Blob([body], { type: fileType });
        let url = URL.createObjectURL(blob);
        Utils.downloadFile(url, fileName);
        attachedFile.isDownloading = false;
      }, err => {
        UIHelper.showErr(err)
        attachedFile.isDownloading = false;
      }
    );
  }
    // options.standard?: "iec" | "jedec";
    public static displayFileSize(size, options?) {
      if (isNaN(Number(size))) {
        return '';
      }
      return filesize(size, options);
    }
  
    public static fileDesc(file: File): string {
      return `${file.name} (${this.displayFileSize(file.size)})`;
    }
}