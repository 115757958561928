import { Router } from "@angular/router";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { PermissionManager } from "./permission-manager";
import { UIHelper } from "./UIHelper";
import { RoleManager } from './role-manager';


export function verifyAfterLogin(authUser, router: Router, preUrl?: string): void {
  // Nếu có preUrl thì quay về trang cũ
  if (PermissionManager.allowLogin(authUser)) {
    if (preUrl) {
      router.navigate([preUrl]);
      return;
    }
    router.navigate([Const.routeDashboard]);
    return;
  }
  let msg = 'You are not allowed to log in to Work portal dashboard.';
  ApiService.instance.logout();
  UIHelper.showDialog(msg);
}

export function verifyPasswordExpired(authUser, router: Router) {
  if(router.url === '/password-expired') return;
  if (authUser?.passwordExpiryDate && new Date(authUser.passwordExpiryDate) < new Date()){
    window.location.href = `/password-expired`;
    return false
  }
  return true;
}

// Vì có trường hợp user đã login vào dashboard từ trước rồi
// Rồi mới bị thay đổi permission nên cần gọi hàm này để logout
export function verifyWhileUsingDashboard(authUser, router: Router): void {
  if (!PermissionManager.allowLogin(authUser)) {
    ApiService.instance.logout();
    router.navigate([Const.routeLogin]);
  }
}

export function requirePermissions(authUser, permissions): boolean {
  return PermissionManager.userHasOneOfPermissions(authUser, permissions);
}

export function isAdmin(authUser): boolean {
  return RoleManager.userHasOneOfRoles(authUser, [RoleManager.admin, RoleManager.adminReadOnly]);
}

export function isAdminReadOnlyRole(authUser): boolean {
  return RoleManager.isAdminReadOnlyRole(authUser);
}

export function isAccounting(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.accounting);
}

export function isAccountingReadOnly(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.accountingReadOnly);
}